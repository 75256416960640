import(/* webpackMode: "eager" */ "/build/f8df4ce0-3c24-4c0b-9517-0456fa889a19/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/build/f8df4ce0-3c24-4c0b-9517-0456fa889a19/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/build/f8df4ce0-3c24-4c0b-9517-0456fa889a19/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/build/f8df4ce0-3c24-4c0b-9517-0456fa889a19/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/build/f8df4ce0-3c24-4c0b-9517-0456fa889a19/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/build/f8df4ce0-3c24-4c0b-9517-0456fa889a19/node_modules/next/dist/client/components/render-from-template-context.js");
